const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备名称",
                prop: "SBMC",
            }, {
                label: "在线状态",
                prop: "ZXZT"
            }, {
                label: "温度(°C)",
                prop: "WD"
            }, {
                label: "湿度(%)",
                prop: "SD"
            }, {
                label: "加热仓温度(°C)",
                prop: "JRCWD"
            }, {
                label: "害虫名称",
                prop: "HCMC"
            }, {
                label: "害虫数量",
                prop: "HCSL"
            }, {
                label: "分布",
                prop: "FB"
            }, {
                label: "病害面积占比（%）",
                prop: "BHMJZB"
            },
                // {
                //     label: "统计时间",
                //     prop: "TJSH"
                // }, {
                //     label: "上报时间",
                //     prop: "SBSJ"
                // },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;